@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
    transition: all .15s ease;
    /* font-family: "Montserrat", sans-serif; */
    font-weight: 500;
}

body {
    font-family: "Montserrat", sans-serif;
    max-width: 1000px;
    margin: 0 auto;
}

#telegram-login-button {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-login {
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.header {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.header-main {
    justify-content: space-between;
}

.header-l {
    justify-content: start;
}

.header-r {
    justify-content: end;
}

.btn {
    &:hover {
        transform: scale(1.05);
    }
}

.userTelegram {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 9px 13px;
    background: linear-gradient(105deg, #1081df 0%, #015ca7 100%);
    border-radius: 15px;
    color: white;
    cursor: pointer;
}

.avatar {
    border-radius: 50%;
    width: 30px;
}

.tg-titlename {
    font-size: 15px;
    font-weight: 500;
    margin-left: 10px;
}

.logOut {
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 3px;
    border: solid 2px #a70101;
    background: white;
    border-radius: 15px;
    color: white;
    cursor: pointer;
    margin-left: 3px;

    &:hover {
        transform: scale(1.05);
        background: #df1313;
        border: solid 2px #df1313;

        .logOutSvg {
            path {
                stroke: #ffffff;
            }
        }
    }

    .logOutSvg {
        max-width: 20px;
        width: 50px;
        height: 50px;

        path {
            stroke: #df1313;
        }
    }
}

@keyframes status-loop {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}

.status {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 15px;
        height: 15px;
        animation: status-loop 1s ease;
    }
}

.status-active {
    background: #24df13;

    &::after {
        background: #2bdf1385;
    }
}

.status-active {
    background: #df1313;

    &::after {
        background: #df131385;
    }
}

.btnCell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.btn-edit {
    outline: none;
    border: 2px solid #1081df;
    color: #1081df;
    background: #ffffff;
    border-radius: 10px;
    min-height: 30px;
    cursor: pointer;

    &:hover {
        border: 2px solid #1081df;
        color: #ffffff;
        background: #1081df;
    }
}

.select-btn {
    outline: none;
    border: 2px solid #1081df;
    color: #1081df;
    background: #ffffff;
    border-radius: 10px;
    min-height: 30px;
    cursor: pointer;

    &:hover {
        border: 2px solid #1081df;
        color: #ffffff;
        background: #1081df;
    }
}

.select-btn-left {
    border-radius: 15px 0 0 15px;
}

.select-btn-right {
    border-radius: 0 15px 15px 0;
}

.select-btn-active {
    border: 2px solid #1081df;
    color: #ffffff;
    background: #1081df;
}


.btn-confirm {
    outline: none;
    border: 2px solid #1fb444;
    color: #1fb444;
    background: #ffffff;
    border-radius: 10px;
    min-height: 30px;
    cursor: pointer;

    &:hover {
        border: 2px solid #1fb444;
        color: #ffffff;
        background: #1fb444;
    }
}

.btn-red {
    outline: none;
    border: 2px solid #df1010;
    color: #df1010;
    background: #ffffff;
    border-radius: 10px;
    min-height: 30px;
    cursor: pointer;

    &:hover {
        border: 2px solid #df1010;
        color: #ffffff;
        background: #df1010;
    }
}

.type-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    min-height: 20px;
    color: white;
    padding: 5px 10px;
}

.tc-phase {
    background: linear-gradient(25deg, rgb(11, 126, 63) 0%, rgba(22, 255, 0, 1) 100%);
}

.tc-project {
    background: linear-gradient(25deg, rgba(214, 12, 113, 1) 0%, rgba(255, 115, 132, 1) 100%);
}

.tc-aps {
    background: linear-gradient(25deg, rgb(16, 109, 185) 0%, rgba(118, 232, 255, 1) 100%);
}

.btns-row {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: end;
}

.delete-btn {
    width: 30px;
    height: 30px;
    border-radius: 15px;
    background: white;
    border: solid #df1313 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 7px;
    cursor: pointer;

    .trash-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 16px;

        path {
            stroke: #df1313;
        }
    }

    &:hover {
        background: #df1313;
        border: solid white 2px;

        .trash-btn {
            path {
                stroke: #ffffff;
            }

        }
    }
}

.modalOverlay {
    position: fixed;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.534);
}

.childTrue {
    position: relative;
}

.childTrue::after {
    content: '';
    width: 12px;
    height: 12px;
    background: #ff1e74;
    border-radius: 50%;
    position: absolute;
    top: -5px;
    right: -5px;
}

.modalWindow {
    margin-top: 30%;
    margin-bottom: 15%;
    padding: 30px;
    min-width: 500px;
    background-color: white;
    border-radius: 15px;
}

.big-btn {
    padding: 7px 15px;
    font-size: 15px;
    font-weight: 600;
}

.formData {
    display: flex;
    flex-direction: column;
}

.input-edit {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    width: 100%;
}

.input_field {
    border-radius: 8px;
    border: 2px solid #1081df;
    font-size: 15px;
    margin-bottom: 3px;
    max-width: 100%;
    width: 100%;
    padding: 5px 5px;
    box-sizing: border-box;
}

.input_field_sec {
    border-radius: 8px;
    border: 2px solid #1081df;
    font-size: 15px;
    margin-bottom: 3px;
    max-width: 100%;
    width: 100%;
    padding: 2px 5px;
}

.input_field_sec_sec {
    border-radius: 8px;
    border: 2px solid #1081df;
    font-size: 15px;
    margin-bottom: 3px;
    /* max-width: 350px; */
    width: 100%;
    padding: 2px 5px;
}

.ups-ttc {
    width: 550px;
    height: 200px;
}


.form-data-block {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 4%;
    margin: 0 200px;
}

.input_field_alt {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    width: 100%;
    margin-top: 5px;
}

.edit_svg {
    cursor: pointer;

    &:hover {
        transform: scale(1.1);
        margin-left: 5px;
    }
}

.image-uploader {
    display: flex;
    flex-direction: column;
    margin-top: 15px;
}

.image-previews {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.image-preview {
    position: relative;
    /* width: 62px;
    height: 62px; */
}

.image-preview p {
    word-wrap: normal;
    font-size: 12px;
    margin: 0 3px;
}

.image-preview img {
    position: relative;
    width: 62px;
    height: 62px;
    object-fit: cover;
    border-radius: 10px;
    border: solid 2px #1081df;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.459);
        border-radius: 10px;
    }
}

.remove-button {
    position: absolute;
    top: 0;
    font-size: 10px;
    right: 0;
    width: 20px;
    height: 20px;
    background: #df1010;
    border: 2px solid #df1010;
    color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:hover {
        background: #ffffff;
        color: #df1010;
        border: 2px solid #df1010;
    }
}

.image-upload-button {
    display: flex;
    align-items: center;

}

.image-upload-button input {
    display: none;
}

.image-upload-button label {
    background: white;
    border: 2px solid #1081df;
    font-size: 30px;
    color: #1081df;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 58px;
    height: 58px;

    &:hover {
        background: #1081df;
        border: 2px solid #1081df;
        color: white;
    }
}

.error-message {
    text-align: center;
    border: 2px solid #c92e2e;
    padding: 5px 10px;
}

.nav-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    border-bottom: 1px solid #0987ee;

}

.nm-btn {
    display: flex;
    align-items: center;
    margin-right: 5px;
    border: 2px solid #1081df;
    border-radius: 10px 10px 0 0;
    border-bottom: none;
    font-size: 15px;
    cursor: pointer;
    padding: 5px 10px;
    background-color: white;
}

.nm-btn-active {
    background-color: #1081df;
    color: white;
}

.namesList {
    display: flex;
    flex-direction: column;
    align-items: start;

    &.users {
        margin-bottom: 100px;
    }
}

.uni_user,
.user_str {
    display: flex;
    align-items: center;
    justify-content: start;
}

.uni_user {
    font-size: 15px;
    border-bottom: 1px solid #1081df;
}

.addictUserStr {
    font-weight: 800;
}

.marinFlexBot {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flexBtns {
    display: flex;
    margin-bottom: 20px;
}


.setNotifi {
    background-color: #35ac2a;
    color: white;
    padding: 5px 10px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.centerial {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.fintab {
    display: flex;
    /* flex-direction: column; */
}

.finderTab-height {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    font-size: 15px;
    border-bottom: 2px solid #1081df;
    margin-bottom: 15px;

}

.fintab-header {
    border-bottom: 3px double #1081df;
    border-right: 3px double #1081df;
    border-left: 3px double #1081df;
    padding-left: 5px;
    padding-bottom: 2px;
}

.borderBot {
    border-bottom: 1px solid #1081df;
}

.finderTab {
    min-width: 250px;
}

.finderTab-bold {
    font-weight: 800;
}

.basicfintab {
    text-align: left;
    align-items: start;
    justify-content: start;
    padding-bottom: 5px;
}

.memory {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-right: 50px;
}

.memlabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;

    p,
    h3 {
        margin: 0;
    }

    h3 {
        font-weight: 700;
    }
}

.disk-bar {
    display: flex;
    align-items: start;
    justify-content: start;
    background-color: #b4b4b4;
    height: 7px;
    overflow: hidden;
    border-radius: 105px;
}

.filled-disk {
    height: 10px;
    background: linear-gradient(205deg, #1081df 0%, #015ca7 100%);
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% -50%);
}